/* styles/FaceRecognition.css */
.face-recognition-container {
    display: flex;
    flex-direction: column;
    padding: 20px;
    color: white;
  }
  
  
  .upload-section {
    position: fixed;
    bottom: 20px;
    left: 100px; /* Adjust based on your sidebar width */
    width: 200px; /* Adjust to match the preview image width */
  }
  
  .preview-section {
    display: flex;
    /* justify-content: center; Centers the section horizontally */
    margin-top: 60px;
    margin-left: 40px;
  }
  
  .preview-image-container {
    display: flex;
    flex-direction: column;
    width: 200px;
  }
  
  .upload-btn {
    margin-bottom: 5px;
    width: 100%;
    padding: 10px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 5px 5px 0 0;
    cursor: pointer;
  }
  
  .preview-image {
    border-radius: 0 0 5px 5px;
    width: 100%;
    height: 200px;
    object-fit: cover;
    background-color: #333;
    border-radius: 0 0 5px 5px;
  }

  .preview-image-placeholder {
    width: 100%;
    height: 200px;
    object-fit: cover;
    background-color: #1a1a1a;
    border-radius: 0 0 5px 5px;
  }
  
  
  .name-register-container {
    display: flex;
    flex-direction: column;
    margin-left: 20px;
    justify-content: center;
  }

  .name-input,
.register-btn {
  width: 200px;
  padding: 10px;
  border-radius: 5px;
  box-sizing: border-box; /* This ensures padding is included in the width */
}

.name-input {
  margin-top: -70px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
}

.register-btn {
  background-color: #0056b3;
  color: white;
  border: none;
  cursor: pointer;
}
  

/*REGISTERED FACES*/
  .registered-faces-section {
    margin-top: 80px;
    margin-left: 40px;
    margin-right: 60px;
  }
  
  .registered-faces-title {
    color: white;
    font-size: 20px;
    text-align: center;
    width: 100%;
    /* background-color: #46BDFF; */
    background: linear-gradient(to right, rgb(139, 28, 139), #0058DA);
    padding: 10px;
    margin-bottom: 20px;
  }
  
  .faces-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
    gap: 20px;
  }
  
  .face-item {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .face-image, .face-image-placeholder {
    width: 150px;
    height: 150px;
    object-fit: cover;
    border-radius: 10px;
    background-color: #333;
  }
  
  .face-info {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 0 10px;
  }
  
  .face-name {
    margin-top: 5px;
    text-align: center;
    flex-grow: 1;
    color: white;
  }
  
  .remove-btn {
    background-color: transparent;
    color: white;
    border: none;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .remove-btn svg {
    width: 16px;
    height: 16px;
    stroke: #dc3545; /* Adjust the icon color */
  }
  