/* styles/Search.css */
.search-page {
    width: 100%;
    /* padding: 20px; */
  }

  /* In your CSS file */
.error-message {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 20vh; /* Adjust as needed to fit your layout */
    text-align: center;
    color: red; /* Optional: change the color as needed */
    background-color: #f8d7da; /* Optional: add background color for better visibility */
    border: 1px solid #f5c2c7; /* Optional: add border for better visibility */
    border-radius: 5px; /* Optional: rounded corners */
    padding: 20px; /* Optional: space inside the box */
  }

  .search-section {
    display: flex;
    flex-direction: column;
    height: calc(100vh - 60px); /* Adjust based on your header height */
  }
  
  .search-content {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    padding: 0; /* Remove padding */
  }
  
  .initial-state {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: #808080;
    font-size: 18px;
    height: 100%;
  }
  
  .initial-state svg {
    margin-bottom: 0px;
  }
  
  .initial-state p {
    margin: 0;
  }
  
  /* Adjust the search header if needed */
  .search-header {
    padding-left: 20px; /* Add left padding to align with video results */
  }