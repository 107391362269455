.video-results {
  padding: 20px;
  background-color: #1e1e1e;
  color: white;
}

.result-count {
  margin-bottom: 20px;
  font-size: 18px;
}

.video-list {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.video-item {
  background-color: #2a2a2a;
  border-radius: 8px;
  overflow: hidden;
  padding: 20px;
}

.video-info {
  margin-bottom: 10px;
}

.video-info h3 {
  margin: 0 0 5px;
  font-size: 18px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.category {
  font-size: 14px;
  color: #aaa;
  margin-bottom: 5px;
}

.video-fragments {
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
}

.video-fragment {
  width: 200px;
  position: relative;
}

.video-fragment img {
  width: 100%;
  height: 120px;
  object-fit: cover;
  cursor: pointer;
  border-radius: 4px;
}

.timestamp {
  font-size: 12px;
  color: #aaa;
  margin-top: 5px;
}

.download-button {
  position: absolute;
  bottom: 5px;
  right: 5px;
  background: rgba(0, 0, 0, 0.5);
  border: none;
  border-radius: 50%;
  padding: 5px;
  cursor: pointer;
}

.download-button svg {
  width: 16px;
  height: 16px;
}
  

.remove-button {
  position: absolute;
  bottom: 10px;
  right: 10px;
  background: none;
  border: none;
  cursor: pointer;
  color: white;
  padding: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.remove-button svg {
  width: 18px;
  height: 18px;
}

  /* Update these styles in your VideoResults.css */

  .video-modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .modal-content {
    position: relative;
    width: 80%;
    max-width: 1200px;
    background-color: #000;
    border-radius: 8px;
    overflow: hidden;
  }
  
  .modal-header {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 1002;
    padding: 10px;
  }
  
  .close-button {
    background-color: black;
    color: white;
    border: none;
    font-size: 20px;
    font-weight: bold;
    cursor: pointer;
    padding: 5px 10px;
    border-radius: 5px;
  }
  
  .close-button:hover {
    background-color: #d32f2f;
  }
  
  .modal-content video {
    width: 100%;
    height: auto;
    display: block;
  }