/* styles/CustomAlert.css */
.custom-alert-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .custom-alert {
    background-color: #2a2a2a;
    color: white;
    border-radius: 8px;
    padding: 20px;
    max-width: 400px;
    width: 90%;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    text-align: center; /* Center the text */
    display: flex;
    flex-direction: column;
    align-items: center; /* Center the content vertically */
  }
  
  .custom-alert p {
    margin: 0 0 20px 0;
    font-size: 18px;
  }
  
  .custom-alert button {
    background-color: #4CAF50;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 4px;
    cursor: pointer;
    font-size: 14px;
    transition: background-color 0.3s ease;
    margin-top: 20px;
  }
  
  .custom-alert button:hover {
    background-color: #45a049;
  }
  