.language-switcher {
    padding: 20px;
    background-color: #0B0B0C;  /* Dark background */
    border-radius: 8px;
    width: 100%;
    max-width: 300px;
    margin-left: 30px;
    margin-top: 30px;
  }
  
  .language-switcher h3 {
    color: white;
    margin-bottom: 20px;
    font-size: 18px;
    font-weight: normal;
  }
  
  .language-buttons {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  
  .language-button {
    display: flex;
    align-items: center;
    padding: 10px 15px;
    background-color: #1E1E1E;
    border: none;
    border-radius: 8px;
    color: white;
    cursor: pointer;
    transition: background-color 0.3s;
    width: 100%;
  }
  
  .flag-container {
    width: 24px;
    margin-right: 15px; /* Increase this value to add more space */
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .language-button span {
    font-size: 14px;
  }
  
  .language-button:hover {
    background-color: #66aad0;
  }
  
  .language-button.active {
    background-color: #46BDFF;
  }
