.downloaded-page {
    padding: 20px;
    color: white;
    height: 100vh;
    display: flex;
    flex-direction: column;
  }
  
  .tab-header {
    margin-top: 125px;
    margin-left: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    margin-bottom: 20px;
  }
  
  .tabs {
    display: flex;
  }
  
  .tab {
    background: none;
    border: none;
    color: white;
    padding: 10px 20px;
    cursor: pointer;
    font-size: 16px;
    border-bottom: 2px solid transparent;
    margin-right: 20px;
  }
  
  .tab.active {
    border-bottom: 2px solid #FF46F8;
  }
  
  .remove-all-button {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #0072F8;
    color: white;
    border: none;
    border-radius: 12px;
    padding: 10px 20px;
    font-size: 14px;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .remove-all-button:hover {
    background-color: #0056b3;
  }
  
  .remove-all-button span {
    margin-right: 10px; /* Add space between text and icon */
  }
  
  .remove-all-button svg {
    width: 16px; /* Adjust icon size */
    height: 16px;
    vertical-align: middle; /* Align icon with text */
  }
  
  .empty-state {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    color: rgba(255, 255, 255, 0.5);
  }
  
  .empty-icon {
    margin-bottom: 20px;
  }
  
  .empty-state p {
    font-size: 18px;
    margin-top: -20px;
  }
  
  .downloaded-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    gap: 20px;
  }
  
  .downloaded-item {
    background-color: #2a2a2a;
    border-radius: 8px;
    overflow: hidden;
  }
  
  .downloaded-item img {
    width: 100%;
    height: 150px;
    object-fit: cover;
  }
  
  .video-info {
    padding: 10px;
  }
  
  .video-info h3 {
    margin: 0 0 10px;
    font-size: 18px;
  }
  
  .video-info p {
    margin: 5px 0;
    font-size: 16px;
  }


  .remove-button {
    position: absolute;
    bottom: 10px;
    right: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 4px;
    background: none;
    border: none;
    cursor: pointer;
    color: white; /* Adjust color as needed */
  }
  
  .remove-button:hover {
    opacity: 0.8;
  }

  .remove-button svg {
    width: 18px;
    height: 18px;
  }

  .video-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    gap: 20px;
  }
  
  .video-item img {
    width: 100%;
    height: 150px;
    object-fit: cover;
    cursor: pointer;
  }
  
  .video-item {
    background-color: #2a2a2a;
    border-radius: 8px;
    overflow: hidden;
    position: relative;
    padding-bottom: 20px; /* Add padding at the bottom for the bin icon */
  }
  
  .video-info {
    padding: 10px;
    padding-right: 30px; /* Add some padding on the right to avoid text touching the edge */
  }
  
  .video-info h3 {
    margin: 0 0 5px;
    font-size: 16px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  
  
  .category {
    font-size: 12px;
    color: #aaa;
    margin-bottom: 5px;
  }

  .filename-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 5px;
  }
  
  
  .timestamp {
    font-size: 14px;
    color: #aaa;
  }

  .play-button {
    position: absolute;
    top: 35%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: rgba(0, 0, 0, 0.6);
    border: none;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .play-button:hover {
    background-color: rgba(0, 0, 0, 0.8);
  }
  
  .play-button svg {
    width: 24px;
    height: 24px;
  }