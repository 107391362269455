.download-section {
    margin-top: 40px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex: 1;
  }
  
.download-icon {
    width: 80px;
    height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #333;
    border-radius: 5px;
    /* margin-bottom: 10px; */
  }

.download-icon svg {
    width: 40px;
    height: 40px;
  }
  
.download-section p {
    cursor: pointer;
    transition: opacity 0.3s ease;
    color: white;
  }

.download-section:hover {
    opacity: 0.8;
  }

.home-header {
    padding: 20px;
    color: white;
    align-self: center;
  }
  
/* CategorySelection.css */
body {
  /* overflow: hidden; */
  background-color: #000;
  color: white;
  font-family: Arial, sans-serif;
}

.home {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
}

.header h1 {
  font-size: 24px;
  margin-bottom: 20px;
}

.category-modal {
  margin-top: 40px;
  background-color: #1a1a1a;
  padding: 30px 20px; /* Increased vertical padding */
  border-radius: 10px;
  width: 90%;
  max-width: 500px;
  min-height: 200px; /* Set a minimum height */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.category-modal h2 {
  font-size: 20px;
  text-align: center;
  margin-bottom: 20px;
  margin-top: 10px;
}

.category-buttons {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 8px;
  margin-bottom: 10px;
}

.category-buttons button {
  background-color: #333;
  color: white;
  border: none;
  padding: 15px 10px; /* Increased vertical padding */
  border-radius: 8px; /* Slightly increased border radius */
  cursor: pointer;
  font-size: 16px; /* Slightly increased font size */
  transition: all 0.3s ease;
  width: 100%; /* Ensure buttons take full width of their grid cell */
  height: 50px; /* Set a fixed height for all buttons */
  display: flex;
  align-items: center;
  justify-content: center;
  transition: transform 0.3s ease; /* This will make the size change smooth */
}

.category-buttons button.selected {
  background: linear-gradient(to right, #FF008A, #0058DA);
  color: white;
}

.category-buttons button:hover {
  transform: scale(0.9); /* This will increase the size by 10% */
  color: white;
}

.category-modal.disabled,
.download-section.disabled {
  opacity: 0.5;
  pointer-events: none;
}

.action-buttons {
  display: flex;
  justify-content: space-between;
}

.action-buttons button {
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 14px;
}

.action-buttons button:first-child {
  background-color: #333;
  color: white;
}

.action-buttons button:last-child {
  background-color: #800080;
  color: white;
}

/* Add a close button */
.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 20px;
  background: none;
  border: none;
  color: white;
  cursor: pointer;
}

.upload-text {
  text-align: center;
}

.upload-text .category {
  display: block;
  margin-bottom: 5px; /* Adds space between the lines */
  font-weight: bold; /* Optional: makes the category name bold */
}

.upload-text .instruction {
  display: block;
}

.complete-icon {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 150px;
  height: 150px;
}

.complete-text {
  margin-top: 10px;
  font-size: 16px;
  color: #4CAF50;
  font-weight: bold;
}

.status {
  margin-top: 20px;
  white-space: pre-line;
}

.upload-icon {
  width: 80px;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  /* background-color: #333; */
  /* border-radius: 5px; */
  /* margin-bottom: 10px; */
}

.loading-icon {
  width: 100px;
  height: 100px;
  position: relative;
}

.spinner {
  width: 100%;
  height: 100%;
  position: relative;
  animation: spin 4s linear infinite;
}

.circle {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  position: absolute;
  opacity: 0.5;
}

.circle1 {
  background-color: rgb(255,167,116);
  top: 0;
  left: 50%;
  transform: translateX(-50%);
}

.circle2 {
  background-color: rgb(62,255,246);
  top: 50%;
  right: 0;
  transform: translateY(-50%);
}

.circle3 {
  background-color: rgb(231,255,126);
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
}

.circle4 {
  background-color: rgb(160,137,255);
  top: 50%;
  left: 0;
  transform: translateY(-50%);
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}


.youtube-form {
    margin-top: 60px;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    max-width: 400px;
  }
  
  .youtube-input {
    width: 280px;
    padding: 10px;
    margin-bottom: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 14px;
    align-items: center;
  }
  
  .youtube-submit {
    align-items: center;
    width: 120px;
    padding: 10px 20px;
    background: #0058DA;
    color: white;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    font-size: 14px;
    transition: background-color 0.3s ease;
  }
  
  /* .youtube-submit:hover {
    background-color: #66aad0;
  }
   */
  .youtube-submit:disabled {
    background-color: #cccccc;
    cursor: not-allowed;
  }