/*Sidebar */
.sidebar {
    width: 80px;
    background-color: #001f3f;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px 0;
    height: 100vh;
  }
  
.logo {
    margin-top: 10px;
    margin-bottom: 40px;
  }
  
.sidebar-buttons {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

.sidebar.disabled {
    opacity: 0.5;
    pointer-events: none;
}

.sidebar-buttons button:disabled {
    cursor: not-allowed;
}

.sidebar-buttons a.active {
    background-color: #46BDFF;
    border-radius: 5px;
  }
  
  .sidebar-buttons a.active svg path {
    fill: white;
  }
  
  /* Update these existing styles */
  .home-button,
  .face-recognition-button,
  .search-button,
  .downloaded-button,
  .settings-button {
    padding: 8px 12px;
    width: 40px;
    height: 40px;
    margin-bottom: 20px;
    background: none;
    border: none;
    cursor: pointer;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: background-color 0.3s ease;
  }
  
  .home-button:hover,
  .search-button:hover,
  .face-recognition-button:hover,
  .downloaded-button:hover,
  .settings-button:hover {
    opacity: 0.8;
    background-color: #66aad0; /* semi-transparent purple */
    border-radius: 5px;
  }
  
  .sidebar-buttons a {
    text-decoration: none;
    color: inherit;
  }

  .logout-button {
    position: absolute;
    display: flex;
    bottom: 0;
    align-items: center;
    justify-content: center;
    background-color: transparent;
    border: none;
    color: white;
    cursor: pointer;
    padding: 8px 12px;
    width: 40px;
    height: 40px;
    transition: background-color 0.3s;
    margin-top: 450px;
    margin-bottom: 10px;
  }
  
  .logout-button:hover {
    background-color: #66aad0;
    border-radius: 5px;
  }
  
  .logout-button svg {
    height: 30px;
    width: 30px;
    margin-right: 0;
  }