/* App and Header */
.app {
  display: flex;
  height: 100vh;
}

.main-content {
  flex: 1;
  display: flex;
  flex-direction: column; 
  background-color: #0B0B0C;
  overflow-x: auto;
  overflow-y: auto;
}

/* In your main CSS file */
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.1); /* This is now 10% opacity */
  z-index: 1000;
}

.disabled {
  opacity: 0.5;
  pointer-events: none;
}

button:disabled {
  cursor: not-allowed;
}
