.header {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  margin-top: 100px;
  /* background-color: #1a1a1a; */
}

.search-container {
  margin-top: 20px;
  display: flex;
  align-items: center;
  max-width: 800px;
  width: 100%;
}

.search-input {
  flex-grow: 1;
  height: 56px;
  padding: 0 15px;
  font-size: 16px;
  border: none;
  border-radius: 8px;
  background-color: #333;
  color: white;
  margin-right: 10px;
}

.button-group {
  display: flex;
  align-items: center;
}

.category-select {
  height: 56px;
  padding: 0 30px 0 15px; /* Increased right padding to accommodate larger icon */
  font-size: 16px;
  border: none;
  border-radius: 8px;
  background-color: #333;
  color: white;
  cursor: pointer;
  margin-right: 10px;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 24 24' fill='none'%3E%3Cpath d='M6 9L12 15L18 9' stroke='white' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: right 15px center; /* Adjusted to center vertically */
  background-size: 16px 16px; /* Control the size of the background image */
  line-height: 56px; /* Match the height to center text vertically */
}

.category-select.error {
  border: 2px solid red;
}

.axtar-button {
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 56px;
  height: 56px;
}

.axtar-button:focus {
  outline: none;
}

@media (max-width: 600px) {
  .search-container {
    flex-direction: column;
    align-items: stretch;
  }

  .search-input {
    margin-right: 0;
    margin-bottom: 10px;
  }

  .button-group {
    justify-content: space-between;
  }

  .category-select {
    flex-grow: 1;
    margin-right: 10px;
  }

  .axtar-button {
    flex-shrink: 0;
  }
}