.search-settings {
    padding: 20px;
    background-color: #0B0B0C;
    border-radius: 8px;
    width: 100%;
    max-width: 300px;
    margin-left: 30px;
    margin-top: 30px;
  }
  
  .settings-title {
    /* Other existing styles */
    margin-left: 50px;
    margin-top: 60px;
  }

  .search-settings h3 {
    color: white;
    margin-bottom: 20px;
    font-size: 18px;
    font-weight: normal;
  }
  
  .settings-container {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  
  .setting-item {
    display: flex;
    flex-direction: column;
    background-color: #1E1E1E;
    border-radius: 8px;
    padding: 10px 15px;
    color: white;
  }
  
  .setting-item label {
    font-size: 14px;
    margin-bottom: 5px;
  }
  
  .setting-item input[type="number"],
  .setting-item input[type="range"] {
    width: 100%;
    background-color: #1E1E1E;
    border: none;
    color: white;
    font-size: 14px;
  }
  
  .setting-item input[type="number"] {
    padding: 5px;
    border-radius: 4px;
  }
  
  .setting-item input[type="range"] {
    -webkit-appearance: none;
    height: 5px;
    border-radius: 5px;
    background: #46BDFF;
    outline: none;
    opacity: 0.7;
    transition: opacity .2s;
  }
  
  .setting-item input[type="range"]:hover {
    opacity: 1;
  }
  
  .setting-item input[type="range"]::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 15px;
    height: 15px;
    border-radius: 50%;
    background: white;
    cursor: pointer;
  }
  
  .setting-item input[type="range"]::-moz-range-thumb {
    width: 15px;
    height: 15px;
    border-radius: 50%;
    background: white;
    cursor: pointer;
  }
  
  .confidence-value {
    font-size: 14px;
    margin-top: 5px;
  }